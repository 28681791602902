import "$styles/index.css"
import "$styles/syntax-highlighting.css"

// Import all JavaScript & CSS files from src/_components
import components from "$components/**/*.{js,jsx,js.rb,css}"

// Import formbricks
import formbricks from "@formbricks/js";

if (typeof window !== "undefined") {
    formbricks.init({
      environmentId: "cm3aev5am000813xrr1won7vj", 
      apiHost: "https://forms.ainv.io",
    });
}

console.info("Bridgetown is loaded!")
